import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import { RainbowKitProvider, darkTheme, connectorsForWallets, getWalletConnectConnector } from '@rainbow-me/rainbowkit';
import { WagmiProvider, createConfig, http } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import {
  coinbaseWallet,
  krakenWallet,
  rabbyWallet,
} from '@rainbow-me/rainbowkit/wallets';
import merge from 'lodash.merge';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import './customRainbowKitStyles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const CustomToastContainer = styled(ToastContainer).attrs({
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  icon: false,
})`
  .Toastify__toast {
    border-radius: 6px;
    color: black;
    font-family: 'BlairITCMedium', sans-serif;
    padding: 16px;
    background: rgb(240, 240, 240);
    box-shadow: 4px 4px #737373;
    border: 1px solid #000;
    font-size: 15px;
  }

  .Toastify__progress-bar {
    background: #be1c1e;
    opacity: 1;
  }

  .Toastify__progress-bar--bg {
    opacity: 0;
  }

  .Toastify__close-button {
    color: black;
  }
`;

const projectId = 'c5f1bf3a8a81b5d596ff61fd4143cd32';

const chains = [mainnet];

const reservoirTheme = merge(darkTheme({ borderRadius: 'small' }), {
  colors: {
    accentColor: '#be1c1e',
  },
  fonts: {
    body: 'BlairITCMedium, sans-serif',
  },
});

const customwalletConnectWallet = ({
  projectId,
  options,
}) => {
  const getUri = (uri) => uri;

  return {
    id: 'walletConnect',
    name: 'Wallet Connect',
    installed: undefined,
    iconUrl: async () => (await import('./assets/walletConnectWallet.svg')).default,
    iconBackground: '#3b99fc',
    qrCode: { getUri },
    createConnector: getWalletConnectConnector({
      projectId,
      walletConnectParameters: options,
    }),
  };
};

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended for Reservoir Mint',
      wallets: [
        coinbaseWallet,
        () => customwalletConnectWallet({ projectId }),
        krakenWallet,
        rabbyWallet,
      ],
    },
  ],
  {
    projectId,
  }
);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient: http(),
  chains,
  transports: {
    [mainnet.id]: http(),
  },
  appName: 'Reservoir Mint',
  projectId
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider theme={reservoirTheme} modalSize="compact">
        <App />
      </RainbowKitProvider>
    </QueryClientProvider>
    <CustomToastContainer />
  </WagmiProvider>
);

reportWebVitals();
