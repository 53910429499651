import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { useScreenDetector } from '../hooks/useScreenDetector';
import CustomConnectButton from './CustomConnectButton';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'BlairITCBold';
    src: url('/fonts/BlairITC-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'BlairITCLight';
    src: url('/fonts/BlairITC-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BlairITCMedium';
    src: url('/fonts/BlairITC-Medium.otf') format('opentype');
    font-weight: medium;
    font-style: normal;
  }

  body {
    font-family: 'BlairITC', sans-serif;
  }

  h2 {
    font-family: 'BlairITCBold', sans-serif;
  }

  h3 {
    font-family: 'BlairITCMedium', sans-serif;
  }

  button {
    font-family: 'BlairITCLight', sans-serif;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 44px;
`;

const Logo = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 99px;
  text-align: center;

  @media (max-width: 820px) {
    left: 20px;
    transform: none;
    text-align: left;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 820px) {
    display: none;
  }
`;

const MenuItem = styled.a`
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  text-decoration: none;
  color: #000;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  &:hover {
    color: #bd1d1d;
  }
`;

const ConnectWalletButton = styled.button`
  background: #1a1b1f;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #32343a;
  }
`;

const ConnectWalletButtonMobile = styled.button`
  background: #1a1b1f;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #32343a;
  }
`;

const WrapperConnectWalletButtonMobile = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
`;

const BurgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 14px;

  @media (max-width: 820px) {
    display: flex;
    margin-left: auto;
  }
`;

const Bar = styled.div`
  width: 14px;
  height: 2.5px;
  background-color: #323232;
  margin: 1px 0;
  transition: 0.4s;
`;

const DropdownMenu = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 105px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: calc(100% - 40px);
  border-radius: 8px;
  padding: 20px;

  ${props => props.open && `
    display: flex;
  `}
`;

const DropdownMenuItem = styled.a`
  padding: 10px 0;
  text-decoration: none;
  color: #323232;
  text-align: center;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 20px;

  &:last-child {
    margin-top: 20px;
    background-color: #323232;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Navbar = () => {
  const { isMobile } = useScreenDetector();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 820 && menuOpen) {
        setMenuOpen(false);
      }
    };

    const handleScroll = () => {
      if (menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavbarContainer>
      <GlobalStyle />
      <Menu>
        <MenuItem href="https://cryptopunks.reservoir-watch.com/#project" target="_blank" rel="noopener noreferrer">Project</MenuItem>
        <MenuItem href="https://cryptopunks.reservoir-watch.com/#reservoir" target="_blank" rel="noopener noreferrer">Reservoir Watch</MenuItem>
        <MenuItem href="https://cryptopunks.reservoir-watch.com/#roadmap" target="_blank" rel="noopener noreferrer">Roadmap</MenuItem>
        <MenuItem href="https://cryptopunks.reservoir-watch.com/#team" target="_blank" rel="noopener noreferrer">Team</MenuItem>
        <MenuItem href="https://cryptopunks.reservoir-watch.com/#faq" target="_blank" rel="noopener noreferrer">FAQ</MenuItem>
      </Menu>
      <Logo src="/reservoirLogo.webp" alt="Reservoir Logo" />
      {!(isMobile) && <CustomConnectButton isMobile={false} closeMenu={() => setMenuOpen(false)} />}
      <BurgerMenu onClick={toggleMenu}>
        <Bar></Bar>
        <Bar></Bar>
        <Bar></Bar>
      </BurgerMenu>
      <DropdownMenu open={menuOpen}>
        <DropdownMenuItem href="https://cryptopunks.reservoir-watch.com/#project" target="_blank" rel="noopener noreferrer">Project</DropdownMenuItem>
        <DropdownMenuItem href="https://cryptopunks.reservoir-watch.com/#reservoir" target="_blank" rel="noopener noreferrer">Reservoir Watch</DropdownMenuItem>
        <DropdownMenuItem href="https://cryptopunks.reservoir-watch.com/#roadmap" target="_blank" rel="noopener noreferrer">Roadmap</DropdownMenuItem>
        <DropdownMenuItem href="https://cryptopunks.reservoir-watch.com/#team" target="_blank" rel="noopener noreferrer">Team</DropdownMenuItem>
        <DropdownMenuItem href="https://cryptopunks.reservoir-watch.com/#faq" target="_blank" rel="noopener noreferrer">FAQ</DropdownMenuItem>
        {(isMobile) && (
          <WrapperConnectWalletButtonMobile>
            <CustomConnectButton isMobile={true} closeMenu={() => setMenuOpen(false)} />
          </WrapperConnectWalletButtonMobile>
        )}
      </DropdownMenu>
    </NavbarContainer>
  );
};

export default Navbar;
