import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from 'styled-components';

const ConnectWalletButton = styled.button`
  background: #1a1b1f;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #32343a;
  }
`;

const ConnectWalletButtonMobile = styled.button`
  background: #1a1b1f;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "BlairITCMedium", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #32343a;
  }
`;

const CustomConnectButton = ({ isMobile, closeMenu }) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        const formatAddress = (address) =>
          `${address.slice(0, 6)}...${address.slice(-4)}`;

        const handleOpenConnectModal = () => {
          closeMenu();
          openConnectModal();
        };

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <>
                    {!isMobile ? (
                      <ConnectWalletButton onClick={handleOpenConnectModal} type="button">
                        Connect Wallet
                      </ConnectWalletButton>
                    ) : (
                      <ConnectWalletButtonMobile onClick={handleOpenConnectModal} type="button">
                        Connect Wallet
                      </ConnectWalletButtonMobile>
                    )}
                  </>
                );
              }

              if (chain.unsupported) {
                return (
                  <>
                    {!isMobile ? (
                      <ConnectWalletButton onClick={openChainModal} type="button">
                        Wrong network
                      </ConnectWalletButton>
                    ) : (
                      <ConnectWalletButtonMobile onClick={openChainModal} type="button">
                        Wrong network
                      </ConnectWalletButtonMobile>
                    )}
                  </>
                );
              }

              return (
                <>
                  {!isMobile ? (
                    <ConnectWalletButton onClick={openAccountModal} type="button">
                      {formatAddress(account.address)}
                    </ConnectWalletButton>
                  ) : (
                    <ConnectWalletButtonMobile onClick={openAccountModal} type="button">
                      {formatAddress(account.address)}
                    </ConnectWalletButtonMobile>
                  )}
                </>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default CustomConnectButton;
