import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Plus, Minus } from 'lucide-react';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';
import { mainnet } from 'viem/chains';
import { toast } from 'react-toastify';

import Navbar from './components/Navbar';
import abi from './abi/abi.json';

const AppContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const FullHeightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding-top: 120px;
`;

const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding: 20px 50px 20px 100px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  font-family: 'BlairITCMedium', sans-serif;
  justify-content: center;

  @media (max-width: 820px) {
    padding: 20px;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .number {
    min-width: 2em;
    text-align: right;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .text {
    text-align: left;
    flex: 1;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 820px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const CenteredTextContainer = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 20px;
`;

const SubHeading = styled.h3`
  font-family: 'BlairITCMedium', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  color: #bd1d1d;
`;

const MainHeading = styled.h2`
  font-family: 'BlairITCMedium', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
`;

const MintContainer = styled.div`
  background-color: rgb(240, 240, 240);
  padding: 20px;
  border-radius: 6px;
  color: black;
  width: 350px;
  user-select: none;
  border: 1px solid #000;
  box-shadow: 4px 4px #737373;

  @media (max-width: 820px) {
    width: 300px;
  }
`;

const NFTImagePlaceholder = styled.div`
  width: 100%;
  height: 350px;
  background-color: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
`;

const TitleNFT = styled.div`
  font-family: 'BlairITCBold', sans-serif;
  font-size: 12px;
  margin-bottom: 7.5px;
`;

const DescriptionNFT = styled.div`
  font-family: 'BlairITCMedium', sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.1px;
  margin-bottom: 20px;
  margin-left: 10px;
`;

const Price = styled.div`
  font-family: 'BlairITCBold', sans-serif;
  font-size: 11px;
  margin-bottom: 15px;
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
`;

const CounterButton = styled.button`
  background-color: black;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;

  &:hover svg {
    stroke: #777777;
  }

  &:disabled svg {
    stroke: #444444;
    cursor: not-allowed;
  }
`;

const CounterValue = styled.div`
  font-family: 'BlairITCMedium', sans-serif;
  font-size: 20px;
  padding: 0 10px;
`;

const MintButton = styled.button`
  font-family: 'BlairITCMedium', sans-serif;
  background-color: #be1c1e;
  color: white;
  font-size: 14px;
  padding: 16px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #cb494a;
  }

  @media (max-width: 820px) {
    padding: 16px 15px;
  }

  &:disabled {
    background-color: #888888;
    color: #cccccc;
    cursor: not-allowed;
  }
`;

const CounterAndMintContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 820px) {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
`;

const Footer = styled.footer`
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid #e4ebf3;
  margin-top: 80px;
  padding: 50px 30px 15px;
  position: relative;

  @media (max-width: 820px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const FooterInfoContainer = styled.div`
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 479px) {
    max-width: none;
  }

  @media (max-width: 991px) {
    max-width: 728px;
  }
`;

const FooterInfoContainerWrapper = styled.div`
  color: black;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterBrand = styled.div`
  max-width: 100%;
  display: inline-block;
`;

const FooterBrandLogo = styled.img`
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
`;

const FooterContent = styled.div`
  display: grid;
  grid-column-gap: 70px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: auto auto 1fr;
  grid-auto-columns: 1fr;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }

  @media screen and (max-width: 991px) {
    grid-column-gap: 60px;
  }
`;

const FooterSectionTitle = styled.div`
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: 'BlairITCMedium', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;

const FooterBlock = styled.a`
  color: black;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  grid-area: span 1 / span 1/ span 1 / span 1;

  &:hover {
    color: #bd1d1d;
  }

  @media screen and (max-width: 820px) {
    align-items: center;
  }
`;

const FooterDivider = styled.div`
  background-color: black;
  width: 100%;
  height: 1px;
  margin-top: 70px;
  margin-bottom: 15px;

  @media screen and (max-width: 820px) {
    margin-top: 60px;
  }
`;

const FooterCopyright = styled.div`
  color: black;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const FrameContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IframeContent = styled.iframe`
  width: 100%;
  height: 1148px;
  border: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;

  @media (max-width: 820px) {
    height: 1408px;
  }
`;

const CustomToastContent = ({ message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>{message}</div>
  </div>
);

function App() {
  const { address, isConnected, status } = useAccount();
  const [wallet, setWallet] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [userNFTs, setUserNFTs] = useState([]);
  const [isMinting, setIsMinting] = useState(false);
  const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
  const [isMinted, setIsMinted] = useState(false);

  const mintPrice = ('3');
  const contractAddress = '0x9deEAb674F48c10F67A776F068aF989289625453';

  useEffect(() => {
    const checkNetwork = async () => {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        if (Number(network.chainId) === mainnet.id) {
          setIsSupportedNetwork(true);
        } else {
          setIsSupportedNetwork(false);
        }
      } catch (error) {
        console.error("Error checking network:", error);
      }
    };

    checkNetwork();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        checkNetwork();
      });
    }

    if (address && isConnected && status === 'connected' && isSupportedNetwork) {
      setWallet({ address, isConnected, status });
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', checkNetwork);
      }
    };
  }, [address, isConnected, status, isSupportedNetwork]);

  const increaseQuantity = () => {
    if (quantity < 30) setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleMint = async () => {
    if (!wallet || !wallet.address) {
      toast.warning(<CustomToastContent message={'Please connect your wallet first.'} />);
      return;
    }

    if (!isSupportedNetwork) {
      toast.warning(<CustomToastContent message={'Please switch network.'} />);
      return;
    }

    try {
      setIsMinting(true);
      setIsMinted(false);
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const totalPrice = (parseFloat(mintPrice) * quantity).toFixed(18);
      const totalPriceInEther = ethers.parseEther(totalPrice);

      const mintTransaction = await contract.safeMint(Number(quantity), {
        value: totalPriceInEther,
        gasLimit: 200000,
      });

      const receipt = await mintTransaction.wait();

      if (receipt) {
        setIsMinted(true);
      } else {
        toast.error(<CustomToastContent message={'Mint failed. Please try again.'} />);
        setIsMinted(false);
      }
    } catch (error) {
      toast.error(<CustomToastContent message={'Mint failed. Please try again.'} />);
      setIsMinted(false);
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <AppContainer>
      <Navbar />
      <FullHeightContainer>
        <ColumnsContainer>
          <LeftColumn>
            <Step>
              <span className="number">1.</span>
              <span className="text">MINT YOUR NFT</span>
            </Step>
            <Step>
              <span className="number">2.</span>
              <span className="text">CHOOSE YOUR PUNK FOR CREATED THE WATCH</span>
            </Step>
            <Step>
              <span className="number">3.</span>
              <span className="text">CLAIM AND STARTING THE CREATION OF YOUR UNIQUE WATCH</span>
            </Step>
          </LeftColumn>
          <RightColumn>
            <MintContainer>
              <NFTImagePlaceholder>
                <StyledVideo
                  src="/nftVideo.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.nextElementSibling.style.display = 'block';
                  }}
                />
                <StyledImage
                  src="/path/to/fallbackImage.jpg"
                  alt="NFT"
                />
              </NFTImagePlaceholder>
              <div>
                <TitleNFT>RESERVOIR watch x CryptoPunks</TitleNFT>
                <DescriptionNFT>Own a unique NFT blending CryptoPunks and watchmaking. A luxury timepiece customized with your CryptoPunk.</DescriptionNFT>
              </div>
              <Price>Price : {mintPrice} ETH</Price>
              <CounterAndMintContainer>
                <CounterContainer>
                  <CounterButton onClick={decreaseQuantity} disabled={quantity === 1}>
                    <Minus color="#565656" size={26} />
                  </CounterButton>
                  <CounterValue>{quantity}</CounterValue>
                  <CounterButton onClick={increaseQuantity} disabled={quantity >= 30}>
                    <Plus color="#565656" size={26} />
                  </CounterButton>
                </CounterContainer>
                <MintButton
                  onClick={handleMint}
                  disabled={isMinting || !isConnected || !address || !mintPrice || !isSupportedNetwork || quantity > 30}
                >
                  {isMinting
                    ? 'Minting...'
                    : isMinted
                      ? 'Minted!'
                      : `${(parseFloat(mintPrice) * quantity).toFixed(2)} ETH MINT`}
                </MintButton>
              </CounterAndMintContainer>
            </MintContainer>
          </RightColumn>
        </ColumnsContainer>
        <IframeContainer>
          <StyledIframe src="https://reservoir-punks.netlify.app?appMode=mint" />
        </IframeContainer>

        <FrameContainer>
          <IframeContent src="https://cryptopunks.reservoir-watch.com/mintdevsc" />
        </FrameContainer>
      </FullHeightContainer>
      <Footer>
        <FooterInfoContainer>
          <FooterInfoContainerWrapper>
            <FooterBrand>
              <FooterBrandLogo src="/reservoir.svg" />
            </FooterBrand>
            <FooterContent>
              <FooterBlock href="projects">
                <FooterSectionTitle>Project</FooterSectionTitle>
              </FooterBlock>
              <FooterBlock href="reservoir">
                <FooterSectionTitle>Reservoir Watch</FooterSectionTitle>
              </FooterBlock>
              <FooterBlock href="team">
                <FooterSectionTitle>Team</FooterSectionTitle>
              </FooterBlock>
              <FooterBlock href="roadmap">
                <FooterSectionTitle>Roadmap</FooterSectionTitle>
              </FooterBlock>
              <FooterBlock href="faq">
                <FooterSectionTitle>FAQ</FooterSectionTitle>
              </FooterBlock>
            </FooterContent>
          </FooterInfoContainerWrapper>
        </FooterInfoContainer>
        <FooterDivider />
        <FooterCopyright>
          Copyright © {new Date().getFullYear()}
        </FooterCopyright>
      </Footer>
    </AppContainer>
  );
}

export default App;
